<template>
  <div class="bonus-program-page">
    <div class="bonus-program-page__header">
      <div class="bonus-program-page__header-title">
        Бонусная программа “Цветочный”
      </div>

      <div class="bonus-program-page__header-subtitle">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu faucibus tortor, ut auctor nunc.
      </div>
    </div>

    <div class="bonus-program-page__button-wrapper">
      <div class="bonus-program-page__button" @click="$router.push({ name: 'bonus-program-upload' })">
        <div class="bonus-program-page__button-name">
          Загрузка чеков
        </div>

        <div class="bonus-program-page__button-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu faucibus tortor, ut auctor nunc.
        </div>
      </div>

      <div class="bonus-program-page__button"  @click="$router.push({ name: 'bonus-program-calculate' })">
        <div class="bonus-program-page__button-name">
          Расчет бонусов
        </div>
        
        <div class="bonus-program-page__button-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu faucibus tortor, ut auctor nunc.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue2-helpers/vue-router";

const router = useRouter();
</script>

<style scoped lang="scss">
@import "src/styles/ui/fontSize";

.bonus-program-page {
  display: flex;
  flex-direction: column;
  gap: var(--sp-6);
  padding: var(--sp-6);

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    width: 100%;
  }

  &__header-title {
    @include add-font-face("h3", false, true);
  }

  &__header-subtitle {
    @include add-font-face("body-2", false, true);
  }

  &__button-wrapper {
    display: flex;
    gap: var(--sp-5);
    width: 100%;
  }

  &__button {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    width: 100%;
    padding: var(--sp-6);
    border-radius: 5px;
    border: 1px solid var(--color-neutral-300);
    cursor: pointer;
  }

  &__button-name {
    @include add-font-face("h3", false, true);
  }

  &__button-description {
    @include add-font-face("body-3", var(--color-neutral-600), true, true);
  }
}
</style>
